import { Fragment, useEffect, useState } from "react";
import { getMembersByBusinessIdService, addMemberService, memberDeleteService } from "../../services";
import useToast from "../../utils/customHooks/useToast";
import { Modal } from "react-bootstrap";
import PhoneNumber from "../../components/PhoneNumber";
import {role as roleList} from "../../utils/constant";
import { separateCountryCodeAndNumber } from "../../utils/helper";
import { RemoveIcon } from "../../components/SVG";
import searchIcon from "../../assets/dashboard/Search.svg";



const rolePermisions = [
    {
        roleName: 'Associate',
        hint: 'Works on assigned projects, manages files, and submits expenses, with access to essential tools but limited administrative rights.',
        canDo: [
            'Only work on assigned projects',
            'Create dealroom files',
            'Create change requests',
            'Create project plans',
            'Add files and folders',
            'Can submit expense',
            'Can access library, client, vendor modules'
        ],
        canNotDo: [
            'See other projects in the pipeline',
            'Add members to projects',
            'View invoices',
            'Approve expenses',
            'Change bank account information',
            'Access reports',
            'Add new team members to account'
        ]
    },
    {
        roleName: 'Manager',
        hint: 'Oversees all projects, manages team assignments, and creates project plans, with access to essential tools but without financial or administrative control',
        canDo: [
            'See all projects in the pipeline',
            'Add existing members to projects',
            'Create dealroom files',
            'Create change requests',
            'Create project plans',
            'Add files and folders',
            'Can submit expense',
            'Can access library, client, vendor modules',
        ],
        canNotDo: [
            'View invoices',
            'Approve expenses',
            'Change bank account information',
            'Access reports',
            'Add new team members to account',
            'Edit permissions of existing team members',
        ]
    },
    {
        roleName: 'Accountant',
        hint: 'Manages financial aspects by viewing invoices, expenses, and reports, without access to project management or administrative tasks',
        canDo: [
            'View invoices',
            'View expenses',
            'Access financial reports',
        ],
        canNotDo: [
            'Change bank account information',
            'See all projects in the pipeline',
            'Create projects',
            'Create invoice',
            'Add members to projects',
            'Create dealroom files',
            'Create change requests',
            'Create project plans',
            'Add files and folders',
            'Submit expense',
            'Access library, client, vendor modules',
        ]
    },
    {
        roleName: 'Admin',
        hint: 'Full access to all features and settings. Admins can manage users, permissions, billing, and system-wide configurations',
        canDo: [
            'See all projects in the pipeline',
            'Add members to projects',
            'Create dealroom files',
            'Create change requests',
            'Create project plans',
            'Add files and folders',
            'Can submit expense',
            'Can access library, client, vendor modules',
            'Create invoices',
            'Approve expenses',
            'Change bank account information',
            'Access reports',
            'Change account settings',
            'Add new team members to account',
            'Edit permissions of existing team members'
        ],
        canNotDo: []
    }
]


const TeamMembers = () => {
    const authUserData = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    const showToast = useToast();

    const [memberFormModal, setMemberFormModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [list, setList] = useState([]);
    const [resErr, setResErr] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhone_number] = useState('');
    const [address, setAddress] = useState('');


    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastNameErr, setLastNameErr] = useState('');
    const [roleErr, setRoleErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [phone_numberErr, setPhone_numberErr] = useState('');
    const [addressErr, setAddressErr] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [activeRole, setActiveRole] = useState({});


    const handleRolePermistionChange = role => {
        let _activeRole = rolePermisions.find(i => i.roleName == role);
        if(_activeRole?.roleName){
            setActiveRole(_activeRole);
        }
    }




    const getAllTeams = async () => {
        const businessId = authUserData?.businessDetail?.businessId;
        if (businessId) {
          try {
            const res = await getMembersByBusinessIdService(businessId);
            if (res.data.status === 1) {
              const members = res.data.responseData.members || [];
              setList(members);
            }
          } catch (error) {
            console.error("Error fetching clients:", error);
          }
        }
    };
    
    useEffect(() => {
        getAllTeams();
    }, []);


    const handleClose = () => {
        setMemberFormModal(false)
        setFirstName('')
        setLastName('')
        setRole('')
        setEmail('')
        setPhone_number('')
        setAddress('')
    }




    const handleValidate = () => {
        let validate = true;
    
        const namePattern = /^[A-Za-z]+$/;
        //const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if (!firstName.trim()) {
            setFirstNameErr('First name is required');
            validate = false;
        } else if (!namePattern.test(firstName.trim())) {
            setFirstNameErr('First name cannot contain numbers or special characters');
            validate = false;
        } else {
            setFirstNameErr('');
        }
    
        if (!lastName.trim()) {
            setLastNameErr('Last name is required');
            validate = false;
        } else if (!namePattern.test(lastName.trim())) {
            setLastNameErr('Last name cannot contain numbers or special characters');
            validate = false;
        } else {
            setLastNameErr('');
        }
    
        if (!email) {
            setEmailErr('Email is required');
            validate = false;
        } else if (!emailPattern.test(email)) {
            setEmailErr('Please enter a valid email address');
            validate = false;
        } else {
            setEmailErr('');
        }
    
        if (!phone_number) {
            setPhone_numberErr('Phone number is required');
            validate = false;
        } else {
            setPhone_numberErr('');
        }
    
        if (!role) {
            setRoleErr('Role is required');
            validate = false;
        } else {
            setRoleErr('');
        }
    
        return validate;
    };
    


    const handleSubmit = async () => {
        if(handleValidate()){
            const { cc, number } = separateCountryCodeAndNumber(phone_number);
            let params = {
                firstName,
                lastName,
                role,
                phone_number: number,
                country_code: cc,
                email,
                address,
                businessId: authUserData.businessDetail.businessId
            }
            setIsSubmitting(true);
            try {
                const res = await addMemberService(params);
                if (res?.data?.status) {
                    getAllTeams();
                    showToast(`Member Add successfully`, "success");
                    handleClose()
                }else{
                    setResErr(res?.data?.error?.message)
                }
            } catch (error) {
                console.error(error);
            }
            setIsSubmitting(false);
        }
    };


    const handleDelete = item => {
        memberDeleteService(item.id).then(res => {
            if (res?.data?.status) {
                getAllTeams();
                showToast(`Member Delete successfully`, "success");
            }
        })
    }


    const filteredList = list.filter(item =>
        item.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );



    return (
        <div className="setting_inner_page">
            <div className="d-flex justify-content-between">
                <div>
                    <h3 className="mb-0">Team Members</h3>
                    <p className="mb-3"><small>Add team members that belong to your company here. Once added, these team members can be added to any project from "Project Team" section inside a project.</small></p>
                </div>
                <div className="ps-5">
                    <span onClick={() => setMemberFormModal(true)} style={{width: '200px'}} className="btn btn-primary">Add Team Member</span>
                </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-5">
                <div className="cm_search">
                    <img src={searchIcon} alt="searchicon" />
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        value={searchTerm} // Bind input to searchTerm
                        onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
                    />
                </div>
              
            </div>


            <div className="cm_table_view mt-4 mb-5">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="ps-2">Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Role</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                filteredList.map((item, index) => (
                                    <tr key={item.id}>
                                        <td></td>
                                        <td className="ps-2">{(!item.firstName && !item.lastName) ? '-' : item.firstName} {item.lastName}</td>
                                        <td>{item.email || ''}</td>
                                        <td>{item.phone_number || '-'}</td>
                                        <td>{item.role || 'Client'}</td>
                                        <td className="text-center">
                                            <span onClick={() => handleDelete(item)} className="cursor-pointer"><RemoveIcon /></span>
                                        </td>
                                    </tr>
                                ))
                            }
                            
                            {filteredList?.length == 0 ? <tr><td style={{textAlign: 'center'}} colSpan={6}>No Data Found</td></tr> : ''}
                            
                        </tbody>
                    </table>
                </div>
            </div>



            <Modal className="medium_padding" show={memberFormModal} onHide={handleClose}  centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Team Member</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="form-group mb-4">
                        <label>First Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={firstName}
                            onChange={e => (setFirstName(e.target.value), setFirstNameErr(''), setResErr(''))}
                        />
                        {firstNameErr && <p className="cm_err">{firstNameErr}</p>}
                    </div>

                    <div className="form-group mb-4">
                        <label>Last Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={e => (setLastName(e.target.value), setLastNameErr(''), setResErr(''))}
                        />
                        {lastNameErr && <p className="cm_err">{lastNameErr}</p>}
                    </div>


                    <div className="form-group mb-4">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={e => (setEmail(e.target.value), setEmailErr(''), setResErr(''))}
                        />
                        {emailErr && <p className="cm_err">{emailErr}</p>}
                    </div>

                    <div className="form-group mb-4">
                        <label>Phone Number</label>
                        <PhoneNumber
                     
                            value={phone_number}
                            setValue={(value) => (setPhone_number(value), setPhone_numberErr(''), setResErr(''))}
                            isError={!!phone_numberErr}
                            setError={() => {}}
                            isSubmitTouch={true}/>

                        {phone_numberErr && <span className="cm_err">{phone_numberErr}</span>}
                    </div>

                    <div className="form-group mb-4">
                        <label>Address</label>
                        <input
                            type="text"
                            value={address}
                            onChange={e => (setAddress(e.target.value), setAddressErr(''), setResErr(''))}
                            className="form-control"
                            placeholder="Address"  />

                        {addressErr && <span className="cm_err">{addressErr}</span>}
                    </div>


                    <div className="form-group mb-4">
                        <label>Role</label>
                        <select
                        
                        value={role}
                        onChange={e => (setRole(e.target.value), setRoleErr(''), setResErr(''), handleRolePermistionChange(e.target.value))}
                        className="form-control"
                        placeholder="Role">
                            <option disabled value="">Select Role</option>
                            {roleList.map(item => (<option value={item.label}>{item.label}</option>))}
                        </select>
                       

                        {roleErr && <span className="cm_err">{roleErr}</span>}
                    </div>

                    <h6>Permissions</h6>

                    {
                        activeRole?.roleName ? <Fragment>
                                <small>{activeRole.hint}</small>

                                <div className="cm_permis cm_can">
                                <h6>Can</h6>
                                <ul>
                                    {
                                        activeRole.canDo.length ? activeRole.canDo.map(item => (
                                            <li key={item}>{item} 
                                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.5 0C4.26016 0 0 4.26016 0 9.5C0 14.7398 4.26016 19 9.5 19C14.7398 19 19 14.7398 19 9.5C19 4.26016 14.7398 0 9.5 0Z" fill="#4BAE4F"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.0952 6.29747C14.3253 6.52755 14.3253 6.90607 14.0952 7.13615L8.52881 12.7026C8.41377 12.8176 8.26162 12.877 8.10947 12.877C7.95732 12.877 7.80518 12.8176 7.69014 12.7026L4.90693 9.91935C4.67686 9.68927 4.67686 9.31075 4.90693 9.08068C5.13701 8.8506 5.51553 8.8506 5.74561 9.08068L8.10947 11.4445L13.2565 6.29747C13.4866 6.06368 13.8651 6.06368 14.0952 6.29747Z" fill="white"/>
                                                </svg>
                                            </li>
                                        )) : ''
                                    }
                                    <li></li>
                                </ul>
                            </div>

                            <div className="cm_permis cm_not_can">
                                <h6>Can not</h6>
                                <ul>
                                    {
                                        activeRole.canNotDo.length ? activeRole.canNotDo.map(item => (
                                            <li key={item}>{item} 
                                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                    <path d="M9.5 0C4.26149 0 0 4.26149 0 9.5C0 14.7385 4.26149 19 9.5 19C14.7385 19 19 14.7385 19 9.5C19 4.26149 14.7385 0 9.5 0Z" fill="#E92A1C"/>
                                                    <path d="M12.7053 11.681C12.9891 11.9649 12.9891 12.4235 12.7053 12.7074C12.5638 12.8489 12.378 12.92 12.1921 12.92C12.0063 12.92 11.8205 12.8489 11.679 12.7074L9.49813 10.5263L7.31724 12.7074C7.17571 12.8489 6.98993 12.92 6.80415 12.92C6.61823 12.92 6.43245 12.8489 6.29092 12.7074C6.00719 12.4235 6.00719 11.9649 6.29092 11.681L8.47194 9.50007L6.29092 7.31913C6.00719 7.03527 6.00719 6.57665 6.29092 6.29278C6.57477 6.00905 7.03338 6.00905 7.31724 6.29278L9.49813 8.47386L11.679 6.29278C11.9629 6.00905 12.4215 6.00905 12.7053 6.29278C12.9891 6.57665 12.9891 7.03527 12.7053 7.31913L10.5243 9.50007L12.7053 11.681Z" fill="#FAFAFA"/>
                                                </svg>
                                            </li>
                                        )) : ''
                                    }
                                  
                                    <li></li>
                                </ul>
                            </div>
                        </Fragment> : ''
                    }
                    

                    
                    
                    



                    {resErr ? <h6 className="cm_err">{resErr}</h6> : ''}

            

                    <div className="d-flex align-items-center justify-content-between mt-5">
                        <span onClick={handleClose} className="btn btn-dark px-5">Cancel</span>
                        <button disabled={isSubmitting} onClick={handleSubmit} className="btn btn-primary px-5">Add</button>
                    </div>
                </Modal.Body>
            </Modal>
            
        </div>
    );
};

export default TeamMembers;







