
import setting from './settings';

export default (() => {
  return {
    'AUTH_LOGIN': setting.api.url + 'auth/login/',
    'AUTH_EMAIL': setting.api.url + 'auth/email/',
    'SEND_OTP': setting.api.url + 'auth/send_otp/',
    'VERIFY_OTP': setting.api.url + 'auth/verify_otp/',
    'RESET_PASS': setting.api.url + 'auth/reset_password/',
    'ONBOARDING_BUSINESS': setting.api.url + 'business/update',
    'BUSINESS_LOGO': setting.api.url + 'business/logo/',
    'BUSINESS_PROJECTS': setting.api.url + 'business/projects/get-project-by-business',
    'RECENT_ACTIVITY': setting.api.url + 'business/recent-activity',
    'ADD_PROJECT': setting.api.url + 'business/projects/add-project',
    'ALL_PROJECTS': setting.api.url + 'business/projects/get-all-project',
    'PROJECT_BY_BUSSINESS': setting.api.url + 'business/projects/get-project-by-business',
    'ADD_CLIENT_TO_PROJECT': setting.api.url + 'business/clients/add-client-to-project',
    'UPDATE_PROJECT': setting.api.url + 'business/projects/update-project',
    'GET_ALL_CLIENTS': setting.api.url + 'business/clients/get-all-client',
    'FAV_PROJECTS': setting.api.url + 'business/projects/get-favorite-project-by-business',
    'GET_CLIENT_BY_PROJECTS': setting.api.url + 'business/clients/get-clients-by-project',
    'GET_MEMBER_BY_PROJECTS': setting.api.url + 'business/members/get-members-by-project',
    'GET_CLIENT_BY_BUSINESS_ID': setting.api.url + 'business/clients/',
    'UPDATE_CLIENT':setting.api.url + 'business/clients/update-client',
    'ADD_CLIENT':setting.api.url + 'business/clients/add-client',
    'GET_MEMBERS_BY_BUSINESS': setting.api.url + 'business/members/get-members-by-business',
    'ADD_PHASE': setting.api.url + 'phase/',
    'GET_PHASE': setting.api.url + 'phase/',
    'UPDATE_PHASE': setting.api.url + 'phase/',
    'JOIN_WAITLIST': setting.api.url + 'waitlist',
    'GET_STATUS': setting.api.url + 'status',
    'ADD_STATUS': setting.api.url + 'status',
    'GET_LEAD_SOURCE': setting.api.url + 'lead-source',
    'ADD_LEAD_SOURCE': setting.api.url + 'lead-source',
    'BUSINESS_SEARCH': setting.api.url + 'business/search/',
    'GET_PROJECT_TEMPLATES': setting.api.url + 'project/dealroom/template',
    'UPDATE_TEMPLATES': setting.api.url + 'project/dealroom/template/update',
    'HTML_TO_PDF': setting.api.url + 'htmltopdf',
    'POST_HTML_TEMPLATES':setting.api.url+'htmltopdf',
    'POST_UPLOAD_DOC':setting.api.url+'docxtohtml',
    'CR_LIST':setting.api.url+'project/cr/list',
    'CR_ADD':setting.api.url+'project/cr/add',
    'CR_UPLOAD_PDF':setting.api.url+'project/cr/uploadpdf',
    'SEND_TO_PDF':setting.api.url+'project/dealroom/sendToPdf',
    'SEND_TO_PDF_FOR_CR':setting.api.url+'project/cr/sendToPdf',
    'ADD_SIGNERS':setting.api.url+'project/dealroom/addsigner',
    'GET_SIGNERS':setting.api.url+'project/dealroom/list',
    'UPDATE_PDF':setting.api.url+'project/dealroom/updatesendToPdf',
    'UPDATE_PDF_FOR_CR':setting.api.url+'project/cr/updatesendToPdf',
    'DELETE_DEALROOM':setting.api.url+'project/dealroom/deletesigner',
    'UPDATE_DEALROOM':setting.api.url+'project/dealroom/updatesigner',
    'UPDATE_DEALROOM_TO_CR':setting.api.url+'project/cr/update',
    'PM_LIST':setting.api.url+'project/pm/list',
    'PM_ADD':setting.api.url+'project/pm/add',
    'PM_UPDATE':setting.api.url+'project/pm/update',
    'PM_DELETE':setting.api.url+'project/pm',
    'EXP_LIST':setting.api.url+'project/expenses/list',
    'EXP_GET_CATEGORY':setting.api.url+'project/expenses/getcategory',
    'EXP_CREATE':setting.api.url+'project/expenses/create',
    'EXP_UPDATE':setting.api.url+'project/expenses/update',
    'EXP_DELETE':setting.api.url+'project/expenses',
    'EXP_REMIND':setting.api.url+'project/expenses/remind',
    'RETRIEVE_SIGNER': setting.api.url+'project/dealroom/retrievesigner',
    'RETRIEVE_SIGNER_CR': setting.api.url+'project/cr/retrievesigner',
    'RETRIEVE_PDF':setting.api.url+'project/dealroom/retrivesendToPdf',
    'UPLOAD_TO_S3':setting.api.url+'project/dealroom/uploadimage',
    'GET_INVOICE_LIST': setting.api.url+'project/invoices/list',
    // 'GET_INVOICE_OVERVIEW': setting.api.url+'project/invoiceoverview',
    'GET_INVOICE_OVERVIEW': setting.api.url+'project/invoiceoverviewchart',
    'GET_INVOICE_OVERVIEW_CHART': setting.api.url+'project/invoiceoverviewchart',
    'GET_EXPENSE_OVERVIEW_CHART': setting.api.url+'project/expenseoverviewchart',
    'CREATE_INVOICE': setting.api.url+'project/invoice/create',
    'CREATE_OFFLINE_INVOICE_RECORD': setting.api.url+'project/offlineinvoice/',
    'INVOICE_TO_PDF': setting.api.url + 'project/invoices/sendToPdf',
    'GET_FILE_FOLDER': setting.api.url + 'project/filefolder/retrieve',
    'GET_FILE_FOLDER_BY_BIS': setting.api.url + 'business/gallery/list',
    'GET_FILE_FOLDER_ALL': setting.api.url + 'business/filefolder/retrieve',
    'GET_GALLERY_LIST': setting.api.url + 'project/gallery/list',
    'GET_GALLERY_LIST_BY_BUSINESS': setting.api.url + 'business/gallery/list',
    'UPDATE_GALLERY': setting.api.url + 'project/gallery/update',
    'UPDATE_GALLERY_BY_BIS': setting.api.url + 'business/gallery/update',
    'GET_GALLERY': setting.api.url + 'project/gallery/retrieve',
    'UPDATE_FILE_FOLDER': setting.api.url + 'project/filefolder/update',
    'UPDATE_FILE_FOLDER_BY_BUSINES': setting.api.url + 'business/filefolder/update',

    'SHARE_FILE': setting.api.url + 'project/filefolder/share',
    'FILE_FOLDER_UOLOAD': setting.api.url + 'project/filefolder/uploadfile',
    'FILE_FOLDER_UOLOAD_BY_BUSINESS': setting.api.url + 'business/filefolder/uploadfile',
    'DELETE_INVOICE': setting.api.url + 'project/invoice/delete',
    'UPDATE_INVOICE': setting.api.url + 'project/invoice/update',
     'GET_NOTES_LIST': setting.api.url + 'project/notes/list',
     'GET_NOTES_LIST_ID': setting.api.url + 'project/notes/',
     'CREATE_NOTES_LIST': setting.api.url + 'project/notes/create',
     'UPDATE_NOTES_LIST': setting.api.url + 'project/notes/update',
     'DELETE_NOTES_LIST': setting.api.url + 'project/notes/',
     'DELETE_STATUS_REPORT': setting.api.url + 'project/statusreport/',
    'DEALROOM_REMIND': setting.api.url + 'project/dealroom/remind',
    'CR_REMIND': setting.api.url + 'project/cr/remind',
    'GALLERY_UPDATE_FAV': setting.api.url + 'project/gallery/updateFav',
    'ADD_MEMBER_TO_PROJECT': setting.api.url + 'business/members/add-member-to-project',
    'GET_TASKS_LIST': setting.api.url + 'project/task/list',
    'GET_TASKS_LIST_ID': setting.api.url + 'project/task/',
    'CREATE_TASKS_LIST': setting.api.url + 'project/task/create',
    'UPDATE_TASKS_LIST': setting.api.url + 'project/task/update',
    'DELETE_TASKS_LIST': setting.api.url + 'project/task/',
    'GET_STATUS_REPORT_LIST': setting.api.url + 'project/statusreport/list',
    'CREATE_STATUS_REPORT': setting.api.url + 'project/statusreport/create',
    'ONBOARD_SUCCESS': setting.api.url + 'business/onboard/token-request',
    'CLIENT_DELETE': setting.api.url + 'business/clients',
    'CLIENT_DELETE_FROM_PROJECT': setting.api.url + 'business/clients/delete-from-project',
    'GET_CLIENTS_BY_BUSINESS': setting.api.url + 'business/clients',
    'MEMBER_DELETE': setting.api.url + 'business/members',
    'MEMBER_DELETE_FROM_PROJECT': setting.api.url + 'business/members/delete-from-project',
    'GET_VENDORS': setting.api.url + 'business/vendors/get-all-vendors',
    'GET_VENDORS_BY_BUSINESS': setting.api.url + 'business/vendors/get-vendors-by-business',
    'ADD_VENDORS': setting.api.url + 'business/vendors/add-vendor',
    'ADD_VENDER_PHOTO': setting.api.url + 'business/vendors/update-vendor-photo',
    'DELETE_VENDER': setting.api.url + 'business/vendors/',
    'UPDATE_VENDER': setting.api.url + 'business/vendors/update-vendor',
    'GET_AUTH_USER_DETAIL': setting.api.url + 'auth/get-user',
    'UPDATE_USER_PROFILE': setting.api.url + 'auth/update_profile/',
    'UPDATE_USER_PROFILE_PHOTO': setting.api.url + 'auth/updateprofilephoto',
    'BUSINESS_ACOUNT_DETAIL': setting.api.url + 'business/accountdetail/get',
    'UPDATE_BUSINESS_ACOUNT': setting.api.url + 'business/accountdetail/update',
    'CREATE_BUSINESS_ACOUNT': setting.api.url + 'business/accountdetail/create',
    'GET_BRAND_DETAIL': setting.api.url + 'business/',
    'ADD_MEMBER': setting.api.url + 'business/members/add-member',
    'GET_MERCHAND_STATUS': setting.api.url + 'business/onboard/merchant-status',
    'MERCHAND_AUTH_LINK': setting.api.url + 'business/onboard/auth-link',
    'GET_PROJECT_BY_ID': setting.api.url + 'business/projects/get-project',
    'UPDATE_CLIENT_PHOTO': setting.api.url + 'business/clients/update-client-photo',
    'GENERATE_SIGNATURE_LINK': setting.api.url + 'generate-signature-link',
    'GENERATE_SIGNATURE_LINK': setting.api.url + 'generate-signature-link',
    'GENERATE_PAYEMENT_LINK': setting.api.url + 'generate-payment-link',
    'INVOICE_REMIND': setting.api.url + 'project/invoices/remind',
    
  }
})()  