import React, { useState, useEffect, useRef } from "react";
import { _contractStatus, invoiceFilterStatus } from "../../utils/constant";
import { capitalizeFirstLetter } from "../../utils/helper";
import filtercircle from "./../../assets/dashboard/filter_cross.svg";

import searchIcon from "./../../assets/dashboard/Search.svg";
import filterIcon from "./../../assets/dashboard/filter_icon.svg";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import threeDot from './../../assets/logos/three-dot.svg';
import ActionModalComponent from "../client-dashboard/ActionModalComponent";

function formatDate(dateStr) {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateStr).toLocaleDateString('en-US', options);
}

const getStatusText = (statusId) => {    
    const status = invoiceFilterStatus.find(status => status.id === statusId);    
    return status ? { text: (status?.value)?.toLowerCase(), color: status?.color } : { text: "-", color: "#000000" };
};

const InvoicesList = ({ list, forReviewsList, isTabView, showActionModal }) => {
    const [search, setSearch] = useState('');
    const [showFilter, setShowFilter] = useState(false);  // State to show/hide filter dropdown
    const [selectedStatus, setSelectedStatus] = useState({});
    const [activeSubTab, setActiveSubTab] = useState('forReview');
    const [activeAction, setActiveAction] = useState({});

    // Ref for the filter dropdown box
    const filterRef = useRef(null);

    // Toggle the filter dropdown
    const toggleFilter = () => {
        setShowFilter(prev => !prev);
    };

    // Handle changes to status filters
    const handleStatusChange = (statusId) => {
        setSelectedStatus(prevStatus => {
            const newStatus = { ...prevStatus, [statusId]: !prevStatus[statusId] };
            // Remove the status from selectedStatus if unchecked
            if (!newStatus[statusId]) {
                delete newStatus[statusId];
            }
            return newStatus;
        });
    };

    // Clear all selected filters
    const clearFilters = () => {
        setSelectedStatus({});
        setShowFilter(false);
    };

    const filteredList = list?.filter(invoice => {
        const matchesSearch = invoice.title.toLowerCase().includes(search.toLowerCase());
        const matchesStatus = Object.keys(selectedStatus).length === 0 || selectedStatus[invoice.status];
        return matchesSearch && matchesStatus;
    });
    const filteredList2 = forReviewsList?.filter(invoice => {
        const matchesSearch = invoice?.title?.toLowerCase()?.includes(search?.toLowerCase());
        const matchesStatus = Object.keys(selectedStatus).length === 0 || selectedStatus[invoice.status];
        return matchesSearch && matchesStatus;
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="invoices_list_wrapper mt-3">
            <div className="d-flex">
                <div className="cm_search">
                    <img src={searchIcon} alt="searchicon" />
                    <input
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="form-control"
                        type="text"
                        placeholder="Search" />
                </div>

                <div className="pro_drop" ref={filterRef}>
                    <span className="btn btn_outline_primary ms-3" onClick={toggleFilter}>
                        <img src={filterIcon} alt="filter icon" /> Filter
                    </span>

                    {showFilter && (
                        <div className="pro_drop_box">
                            <div className="project_filter_upper mb-0">
                                <h4 className="filter_popup_heading">Filter</h4>
                                <img
                                src={filtercircle}
                                onClick={() => setShowFilter(false)}
                                alt="Close filter"
                                />
                            </div>
                            <div className="pro_drop_box_body">
                                {/* <button 
                                    className="btn btn-outline-secondary mb-3" 
                                    onClick={clearFilters}
                                >
                                    Clear Filters
                                </button> */}

                                <Accordion>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Status</Accordion.Header>
                                        <Accordion.Body>
                                        <ul className="filter_dropdown_ul">
                                            {invoiceFilterStatus.map((filterItem) => (
                                                <li key={filterItem.id} className="filter_dropdown_li">
                                                    <input 
                                                        type="checkbox" 
                                                        checked={!!selectedStatus[filterItem.id]} 
                                                        onChange={() => handleStatusChange(filterItem.id)}
                                                    />
                                                    <label>{filterItem.value}</label>
                                                </li>
                                            ))}
                                        </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>



                                
                                
                            </div>

                            <div className="modal_btn_div project_filter_btn">
                                <button
                                className="_btn_transparent_ modal_div_button"
                                onClick={() => setShowFilter(false)}
                                >
                                <span>Cancel</span>
                                </button>
                                <button
                                className="_btn_normal_ modal_div_button"
                                onClick={() => setShowFilter(false)}
                                >
                                <span>Submit</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {isTabView ? <Tabs className="mt-3 mb-3" activeKey={activeSubTab} onSelect={e => (setActiveSubTab(e), setSearch(''))}>
                <Tab eventKey="forReview" title="For Review">
                <div className="cm_table_view mt-4 mb-5">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Invoice ID</th>
                                    <th>Date</th>
                                    <th>Title</th>
                                    <th>Project</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Submitted By</th>
                                    {showActionModal ? <th className="text-start">Action</th> : ''}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredList2?.map((invoice, index) => (
                                    <tr key={invoice?.id}>
                                        <td>{index + 1}</td>
                                        <td>{formatDate(invoice.expenseDate)}</td>
                                        <td>{invoice.title}</td>
                                        <td>{invoice.projectName}</td>
                                        <td>₹ {invoice?.amount?.toFixed(2)}</td>
                                        <td>
                                        <span
                                            style={{
                                            backgroundColor: _contractStatus[invoice?.reviewStatus]?.color,
                                            padding: "4px 10px",
                                            fontSize: "12px",
                                            lineHeight: "16px",
                                            color: "#ffffff",
                                            fontWeight: 400,
                                            borderRadius: "4px",
                                            minWidth: '80px',
                                            display: 'block',
                                            textAlign: 'center'
                                            }}
                                        >
                                            {_contractStatus[invoice?.reviewStatus]?.value}
                                        </span>
                                        </td>
                                        <td>{invoice.createBy}</td>
                                        {showActionModal ? <td onClick={() => setActiveAction(invoice)} className="text-start" style={{ cursor: 'pointer' }}>
                                     <img src={threeDot} alt="three dot" />
                                    </td> : ''}
                                    </tr>
                                ))}
                                {filteredList2?.length == 0 ? <tr><td style={{textAlign: 'center'}} colSpan={7}>No Data Found</td></tr> : ''}
                            </tbody>
                        </table>
                    </div>

                    {activeAction.id ? <ActionModalComponent
                                    _data={activeAction}
                                    title={activeAction.title}
                                    onClose={() => setActiveAction({})}
                                    url={activeAction?.pdfRecord?.pdfFile}
                                    viewType="INVOICE" /> : ''}
                </div>
                </Tab>

                <Tab eventKey="myExpenses" title="My Expenses">
                <div className="cm_table_view mt-4 mb-5">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Invoice ID</th>
                                    <th>Date</th>
                                    <th>Title</th>
                                    <th>Project</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Submitted By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredList?.map((invoice, index) => (
                                    <tr key={invoice?.id}>
                                        <td>{index + 1}</td>
                                        <td>{formatDate(invoice.expenseDate)}</td>
                                        <td>{invoice.title}</td>
                                        <td>{invoice.projectName}</td>
                                        <td>₹ {invoice?.amount?.toFixed(2)}</td>
                                        <td>
                                        <span
                                            style={{
                                            backgroundColor: _contractStatus[invoice?.reviewStatus]?.color,
                                            padding: "4px 10px",
                                            fontSize: "12px",
                                            lineHeight: "16px",
                                            color: "#ffffff",
                                            fontWeight: 400,
                                            borderRadius: "4px",
                                            minWidth: '80px',
                                            display: 'block',
                                            textAlign: 'center'
                                            }}
                                        >
                                            {_contractStatus[invoice?.reviewStatus]?.value}
                                        </span>
                                        </td>
                                        <td>{invoice.createBy}</td>
                                    </tr>
                                ))}

                                {filteredList?.length == 0 ? <tr><td style={{textAlign: 'center'}} colSpan={7}>No Data Found</td></tr> : ''}
                            </tbody>
                        </table>
                    </div>
                </div>
                </Tab>
            </Tabs>
            :
            (
                <div className="cm_table_view mt-4 mb-5">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Invoice ID</th>
                                <th>Date</th>
                                <th>Title</th>
                                <th>Project</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Submitted By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList?.map((invoice, index) => (
                                <tr key={invoice?.id}>
                                    <td>{index + 1}</td>
                                    <td>{formatDate(invoice.expenseDate)}</td>
                                    <td>{invoice.title}</td>
                                    <td>{invoice.projectName}</td>
                                    <td>₹ {invoice?.amount?.toFixed(2)}</td>
                                    <td>
                                    <span
                                        style={{
                                        backgroundColor: _contractStatus[invoice?.reviewStatus]?.color,
                                        padding: "4px 10px",
                                        fontSize: "12px",
                                        lineHeight: "16px",
                                        color: "#ffffff",
                                        fontWeight: 400,
                                        borderRadius: "4px",
                                        minWidth: '80px',
                                        display: 'block',
                                        textAlign: 'center'
                                        }}
                                    >
                                        {_contractStatus[invoice?.reviewStatus]?.value}
                                    </span>
                                    </td>
                                    <td>{invoice.createBy}</td>
                                </tr>
                            ))}
                             {filteredList?.length == 0 ? <tr><td style={{textAlign: 'center'}} colSpan={7}>No Data Found</td></tr> : ''}
                        </tbody>
                    </table>
                </div>
            </div>
            )
            }

            
        </div>
    );
};

export default InvoicesList;
