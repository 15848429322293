import React, { useEffect, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import filterIcon from "./../../../assets/dashboard/filter_icon.svg";
import plusIcon from "./../../../assets/dashboard/plus.svg";
import searchIcon from "./../../../assets/dashboard/Search.svg";
import threeDot from "./../../../assets/logos/three-dot.svg";
import InvoiceMain from "./invoiceMain";
import { deleteInvoiceService, getInvoiceList, getInvoiceOverview, updateInvoiceService } from "../../../services";
import { invoiceFilterStatus } from "../../../utils/constant";
import ProjectFilter from "../../../modals/DealroomFilter";
import ActionModal from "../../../modals/ActionModal";
import { useSelector } from "react-redux";
import useToast from "../../../utils/customHooks/useToast";
import { useLocation } from "react-router-dom";
import OfflineRecord from "./OfflineRecord";
import { formatAmount } from "../../../utils/helper";

export default function Invoices({isGlobal, activeTab}) {
  const location = useLocation();
  const isFromInvoicesModule = localStorage.getItem('isFromInvoicesModule');
  
  const { id } = location.state || {};
  const showToast = useToast();
  const [currentView, setCurrentView] = useState(false);
  const [openOfflineRecord, setopenOfflineRecord] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [filteredInvoiceList, setFilteredInvoiceList] = useState([]);
  const [invoiceOverview, setInvoiceOverview] = useState({});
  const [closeInvoice, setCloseInvoice] = useState(localStorage.getItem("closeInvoiceMain"));
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [actionTitle, setActionTitle] = useState('');
  const [listId, setListId] = useState('');
  const [currentPdf, setCurrentPdf] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedInvoiceAmount, setselectedInvoiceAmount] =  useState(null)
  const [invoiceitem, setinvoiceItem] = useState(null)
  const [filter, setFilter] = useState({
    status: {},
  });
  const [showProjectFilter, setShowProjectFilter] = useState(false);
  const [isFilterRun, setIsFilterRun] = useState(false);
  const [filterError, setFilterError] = useState(false);
  const [searchError, setSearchError] = useState(false)
  const [renderList, setrenderList] = useState(false)

  const [savedFilters, setSavedFilters] = useState(null);

  const _status = useSelector(state => state?.status?.status?.status);

  const initialStatus = [
    ...invoiceFilterStatus.map(item => ({
      statusName: item.label,
      status: false,
    }))
  ];

  useEffect(() => {
    setFilter({
      status: initialStatus.reduce((acc, item) => {
        acc[item.statusName] = item.status;
        return acc;
      }, {}),
    });
  }, [_status]);

  useEffect(() => {
      return () => {
          localStorage.removeItem('formValueData');
      };
  }, []);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // Debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    applyFilter();
  }, [debouncedSearchTerm, invoiceList, filter]);

  const applyFilter = () => {
    const searchTermLower = debouncedSearchTerm.toLowerCase();
    const statusFiltersActive = Object.values(filter.status).some(value => value);
  
    if (!statusFiltersActive && searchTermLower === "") {
      setFilteredInvoiceList(invoiceList);
      setSearchError(false);
      setFilterError(false);
      return;
    }
  
    if (!statusFiltersActive) {

      const filteredBySearch = invoiceList.filter(invoice =>
        (invoice.title?.toLowerCase().includes(searchTermLower) || 
        invoice.createBy?.toLowerCase().includes(searchTermLower))
      );
  
      // If there are no results from the search
      if (searchTermLower && filteredBySearch?.length === 0) {
        setSearchError(true);
      } else {
        setSearchError(false);
      }
  
      setFilteredInvoiceList(filteredBySearch);
      // setSearchError(false);
      setFilterError(false);
      return;
    }
  
    const filtered = invoiceList.filter(invoice => {
      const invoiceStatus = invoiceFilterStatus.find(status => status.id === invoice.status)?.value.toLowerCase();
      
      const statusFilterActive = Object.entries(filter.status)
        .some(([key, value]) => value && key.toLowerCase() === invoiceStatus);
  
      const searchMatch = invoice.title.toLowerCase().includes(searchTermLower) || 
                          invoice.createBy.toLowerCase().includes(searchTermLower);
  
      return statusFilterActive && searchMatch;
    });

    setFilteredInvoiceList(filtered);
    setFilterError(filtered?.length === 0 && statusFiltersActive);    
    setSearchError(filtered?.length === 0 && searchTermLower !== "");
  };  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleProjectFilterClick = () => {
    setShowProjectFilter(true);
  };

  const handleCloseActionModal = () => {
    setActionTitle('');
    setIsActionModalOpen(false);
    fetchData();
  };
  const [itemTitle, setItemTitle] = useState("")
  const actionModalHandler = (item) => {    
    if (item.id) {
      setinvoiceItem(item)
      setselectedInvoiceAmount(item?.invoiceAmount?.totalAmount)
      setItemTitle(item?.title)
      setIsActionModalOpen(true);
      setListId(item?.id);
      setActionTitle(item?.status);
      setCurrentPdf(item?.pdfRecord?.pdfFile);
    }
  };

  const actionHandler = (id, type) => {
    if(type === 2){
      try {
        deleteInvoiceService(id).then(res => {
          if(res.data.status === 1){
            showToast("Invoice deleted successfully.", "success")
          // Update the invoice list after deletion
            setInvoiceList(prevList => prevList.filter(invoice => invoice.id !== id));
            setFilteredInvoiceList(prevList => prevList.filter(invoice => invoice.id !== id));
            setrenderList(true)
            fetchData();
          }else{
            showToast("Error deleting invoice.", "error")
          }
        })
      } catch (error) {
        
      }
    }
    if(type === 1){
      let data = {
        invoiceId:id,
        status:5
      }
      try {
        updateInvoiceService(data).then(res => {
          if(res.data.status === 1){
            showToast("Invoice canceled successfully.", "success")
            setrenderList(true)
            fetchData();
          }else{
            showToast("Error updating invoice.", "error")
          }
        })
      } catch (error) {
        
      }
    }
    if(type === 6){
      let data = {
        invoiceId:id,
        status:16
      }
      try {
        updateInvoiceService(data).then(res => {
          if(res.data.status === 1){
            showToast("Invoice paid successfully.", "success")
            setrenderList(true)
            fetchData();
          }else{
            showToast("Error updating invoice.", "error")
          }
        })
      } catch (error) {
        
      }
    }
  }



  const handleOpenInvoice = () => {
    setCurrentView(true);
    setopenOfflineRecord(false)
  };

  const handleOpenOfflineRecord = () =>{
    setopenOfflineRecord(true)
    setCurrentView(false);
    fetchData();
  }

  useEffect(() => {
    if(isFromInvoicesModule){
      setCurrentView(true);
      localStorage.removeItem('isFromInvoicesModule');
    }
  }, [isFromInvoicesModule])

  const handleCancel = () => {
    setCurrentView(false);
    fetchData();
    setCloseInvoice(localStorage.getItem("closeInvoiceMain"));
    localStorage.removeItem('formValueData');
  };

  const handleCancelOfflineRecord = () =>{
    setopenOfflineRecord(false)
  }


  const fetchData = async () => {
    const projectDetails = JSON.parse(localStorage.getItem('projectDetail2'));
    const projectId = id || '';
    
    const businessId = projectDetails?.businessId || '';
    const token = localStorage.getItem("token");

    if (projectId) {
      try {
        const invoiceListResponse = await getInvoiceList(projectId, token);
        if (invoiceListResponse.data.status === 1) {
          setInvoiceList(invoiceListResponse?.data?.responseData?.data);
          setFilteredInvoiceList(invoiceListResponse?.data?.responseData?.data);
        }

        const invoiceOverviewResponse = await getInvoiceOverview(projectId, businessId, token);
        if (invoiceOverviewResponse.data.status === 1) {
          setInvoiceOverview(invoiceOverviewResponse?.data?.responseData?.invoiceOverview);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }
  };

  useEffect(() => {
    

    fetchData();
  }, [currentView, renderList, openOfflineRecord, activeTab]);

  const getStatusText = (statusId) => {    
    const status = invoiceFilterStatus.find(status => status.id === statusId);    
    return status ? { text: (status?.value)?.toLowerCase(), color: status?.color } : { text: "-", color: "#000000" };
  };
  const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  function formatDate(dateStr) {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateStr).toLocaleDateString('en-US', options);
  }

  // const totalReceivedAmount = 


  return (
    <div style={{  overflowY: "scroll", paddingRight: "10px" }} className="invoice_main_div">
      {currentView || openOfflineRecord ? (
        <>
        {
          currentView ? (
            <InvoiceMain isGlobal={isGlobal} onCancel={handleCancel} />
          ) : 
          (
            <OfflineRecord onCancel={handleCancelOfflineRecord} invoiceAmount={selectedInvoiceAmount} invoiceItem={invoiceitem}  />
          )
        }
        </>

      ) : (
        <>
          <div className="dealroom_detail_upper _flex_end_">
            <div className="dealroom_detail_upper_right _flex_gap_30">
              <CommonButton
                label="Invoice"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={plusIcon}
                onClick={handleOpenInvoice}
              />
              <CommonButton
                label="Record Manual/Offline Payment"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={false}
                onClick={handleOpenOfflineRecord}
              />
            </div>
          </div>

          <div style={{ maxHeight: "450px", overflowY: "auto", margin: "54px 0 40px" }}>
            <table className="project_table dealroom_table" style={{ marginTop: 0 }}>
              <thead>
                <tr>
                  <th className="text-start ps-4">Total Amount</th>
                  <th className="text-start">Received</th>
                  <th className="text-start">Outstanding (includes overdue amt. too)</th>
                  <th className="text-start">Overdue {invoiceOverview?.overdueDays} Days</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-start ps-4">₹ {formatAmount(invoiceOverview?.totalAmount)}</td>
                  <td className="text-start">₹ {formatAmount(invoiceOverview?.receivedAmount)}</td>
                  <td className="text-start">₹ {formatAmount(invoiceOverview?.outstandingAmount)}</td>
                  <td className="text-start">₹ {formatAmount(invoiceOverview?.overdueAmount)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="dealroom_detail_upper _flex_row_spacebtw_">
            <div className="dealroom_detail_upper_left _flex_gap_30">
              <div className="dashboard_upper_input">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <img src={searchIcon} alt="searchIcon" />
              </div>
              <CommonButton
                label="Filter"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn white_bg_btn"
                icon={filterIcon}
                onClick={handleProjectFilterClick}
              />
              {showProjectFilter && (
                <ProjectFilter 
                  handleFilterClose={() => { setShowProjectFilter(false); setIsFilterRun(true); }}
                  filterBy={filter}
                  invoiceFilter={filter}
                  setFilterBy={setFilter}
                  isInvoice={true}
                  saveData={savedFilters}
                />
              )}
            </div>
          </div>

          <h2 className="dashboard_heading">Invoice Details</h2>
          <p className="font_14">
            Reminders for documents will be sent every 2 days until signed.
          </p>

          <div>
          <table className="project_table dealroom_table">
              <thead>
                <tr>
                  <th className="text-start ps-4">Invoice ID</th>
                  <th className="text-start">Date</th>
                  <th className="text-start">Title</th>
                  <th className="text-start">Amount</th>
                  <th className="text-start">Status</th>
                  <th className="text-start">Sent By</th>
                  <th className="text-start">Record Amount</th>
                  <th className="text-start">Due Date</th>
                  <th className="text-start">Actions</th>
                </tr>
              </thead>
              <tbody>
                {invoiceList?.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="no-data-message">
                      No invoices available.
                    </td>
                  </tr>
                ) : filterError ? (
                  <tr>
                    <td colSpan="8" className="no-data-message">
                      No results match the filter criteria.
                    </td>
                  </tr>
                ) : searchError ? (
                  <tr>
                    <td colSpan="8" className="no-data-message">
                      No results match the search criteria.
                    </td>
                  </tr>
                ) : (
                  filteredInvoiceList.map((invoice, index) => {
                    const { text, color } = getStatusText(invoice?.status);
                    
                    // Calculate the total offline records amount for the current invoice
                    const offlineRecords = invoice.offlineRecords || [];
                    const totalOfflineAmount = offlineRecords.reduce((sum, record) => sum + record.receiveAmount, 0);
                    
                    return (
                      <tr key={invoice?.id}>
                        <td className="text-start ps-4">{index + 1}</td>
                        <td className="text-start">{formatDate(invoice.created_at)}</td>
                        <td className="text-start">{invoice.title}</td>
                        <td className="text-start">₹ {formatAmount(invoice?.invoiceAmount?.totalAmount)}</td>
                        <td className="text-start" style={{ color: color }}>{capitalizeFirstLetter(text)}</td>
                        <td className="text-start">{invoice.createBy}</td>
                        <td className="text-start">{formatAmount(totalOfflineAmount)}</td>
                        <td className="text-start">{formatDate(invoice.dueDate)}</td>
                        <td className="text-start" style={{ cursor: 'pointer' }}>
                          <span onClick={() => actionModalHandler(invoice)}>
                            <img src={threeDot} alt="three dot" />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
                 {/* {filteredInvoiceList?.length == 0 ? <tr><td>No Data Found</td></tr> : ''} */}
              </tbody>
          </table>
          </div>

          {isActionModalOpen && <ActionModal
            isOpen={isActionModalOpen}
            title={actionTitle}
            isCRView={true}
            onClose={handleCloseActionModal}
            dealRoomID={listId}
            onSave={actionHandler}
            pdf={currentPdf}
            invoiceitem={invoiceitem}
            isInvoice={true}
            itemTitle={itemTitle}
            handleOpenOfflineRecord={handleOpenOfflineRecord}
          />}
        </>
      )}
    </div>
  );
}
