import React, { useEffect, useState } from "react";
import searchicon from "../assets/dashboard/Search.svg";
import userimg from "../assets/dashboard/user_img.svg";
import plusicon from "../assets/dashboard/pluscircle.svg";
import plusblue from "../assets/dashboard/plus_blue.svg";
import staricon from "../assets/dashboard/star.svg";
import CommonButton from "../components/common/CommonButton";
import { useNavigate } from "react-router-dom";
import PipeLine from "../components/PipeLine";
import { Modal, Button } from "react-bootstrap";
import "./dashboardtask.css"

import {
  addLeadSourceService,
  addPhaseService,
  addStatusService,
  getBusinessProjectsService,
  getFavProjectService,
  getInvoiceOverview,
  getLeadSourceService,
  getNotesList,
  getPhaseService,
  getRecentActivitiesService,
  getStatusService,
  getTasksList,
} from "../services";
import QuickActions from "../components/QuickActions";
import { useDispatch } from "react-redux";
import { addPhases } from "../features/phase/phaseSlice";
import { useSelector } from "react-redux";
import { addStatus } from "../features/status/statusSlice";
import { persistor } from "../store";
import { logout } from "../features/auth/authSlice";
import GlobalSearch from "../components/common/GlobalSearch";
import { leadSource, leadSourceWithOthers, projectOverviewLeadSource, projectStage, projectStatus } from "../utils/constant";
import { addProjects } from "../features/projects/projectsSlice";
import { addLeadSource } from "../features/leadSource/leadSourceSlice";
import {  NotesformatDate2 } from "../utils/helper";
import Loader from "../components/common/Loader";

export default function Dashboard() {
  const navigate = useNavigate()
  const [businessLogo, setBusinessLogo] = useState('')
  const [activity, setActivity] = useState([])
  const [projects, setProjects] = useState([])
  const [pipeLine, setPipeLine] = useState([])
  const [_superVisor, _setSuperVisor] = useState(null)
  const [favProjects, setFavProjects] = useState([])
  const dispatch = useDispatch()
  const phases = useSelector(state => state?.phases?.phases?.phase) || null
  const id = useSelector(state => state?.phases?.phases?.id) || null
  const businessId = useSelector(state => state?.phases?.phases?.businessId) || null
  const [nameInitials, setNameInitials] = useState('');
  const [invoiceShow, setInvoiceShow] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [showPipeline, setShowPipeline] = useState(false)
  const [invoiceOverview, setInvoiceOverview] = useState({})
  const [invoiceOverviewShow, setInvoiceOverviewShow] = useState({});
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    if((phases !== null || phases !== 'null') && (showPipeline === false) && pipeLine.length > 0){
      setShowPipeline(true)
    }
  },[phases, pipeLine])

  useEffect(() => {
    // window.location.reload()
    getStatus()
    getPhase()
    getLeadSource()
    getBusinessProjects();
    getRecentActivities();
    getFavouriteProjects();
    localStorage.removeItem('ProjectDetails')
    localStorage.removeItem('projectDetail')
    localStorage.removeItem('projectDetail2')
    localStorage.removeItem('filterCheks')

    let isSuperVisor = localStorage.getItem('isSuperVisor')
    let userData = JSON.parse(localStorage.getItem('authUserData'))
    let logo = userData?.businessDetail?.logo || ''
    let role = userData.role || ''
    let referrer = userData?.businessDetail?.referrer || ''
    let leadSource = JSON.parse(localStorage.getItem('leadSource')) || undefined
    if(leadSource === undefined || leadSource === null){
      if(referrer){
        let newLeadSource = []
        let _referrer = referrer.charAt(0).toUpperCase() + referrer.slice(1);
        newLeadSource.push({label: _referrer, value: _referrer})
        let finaLLeaseSource = []
        if(_referrer === 'Other'){
          finaLLeaseSource = [...projectOverviewLeadSource]
        }else{
          finaLLeaseSource = [...newLeadSource, ...projectOverviewLeadSource]
        }
        localStorage.setItem('leadSource',JSON.stringify(finaLLeaseSource))
      }else{
        localStorage.setItem('leadSource',JSON.stringify(projectOverviewLeadSource))
      }
    }

    if(role === "Owner" || role === 'Accountant' || role === "Admin"){
      setInvoiceShow(true)
    }else{
      setInvoiceShow(false)
    }


    if(isSuperVisor === false || isSuperVisor === true){
      _setSuperVisor(isSuperVisor)
    }

    if(logo){
      setBusinessLogo(logo)
    }
  }, []);

  const getPhase = () => {
    let userData = JSON.parse(localStorage.getItem('authUserData'))
    let businessID = userData?.businessDetail?.businessId
    getPhaseService(businessID).then(res => {
          if(res.statusCode === 200){
          if(res.data.status === 1){
          dispatch(addPhases(res.data.responseData))
          }else{
            if(res.data.status === 0){
              try {
                const combinedPhases = [
                  ...projectStage.map(stage => ({
                    name: stage.label,
                    color: stage.color
                  }))
                ];

                const mergedPhases = combinedPhases.map((phase, index) => ({
                  name: phase?.name,
                  isSelected: true, // Assuming all phases are initially not selected
                  position: index + 1,
                  color: phase?.color
                }));

                
                const phaseSchema = {
                  businessId: businessID,
                  phase: mergedPhases
                };

                addPhaseService(phaseSchema).then(res => {
                    if(res.data.status === 1){
                      dispatch(addPhases(res.data.responseData))
                  }
                })
              } catch (error) {
                
              }
            }
          }
        }
    })
  }

  const getStatus = () => {
    let userData = JSON.parse(localStorage.getItem('authUserData'))
    let businessID = userData?.businessDetail?.businessId
      try {
          getStatusService(businessID).then(res => {
                if(res.statusCode === 200){
                if(res.data.status === 1){
                dispatch(addStatus(res.data.responseData))
                }else{
                  if(res.data.status === 0){
                    try {

                      const mergedStatus = projectStatus.map((status, index) => ({
                        name: status?.label,
                        isSelected: false, // Assuming all status are initially not selected
                        position: index + 1,
                        color: status?.color
                      }));

                      const statusSchema = {
                        businessId: businessID,
                        status: mergedStatus
                      };

                      addStatusService(statusSchema).then(res => {
                          if(res.data.status === 1){
                            dispatch(addStatus(res.data.responseData))
                        }
                      })
                    } catch (error) {
                      
                    }
                  }
                }
              }
          })
      } catch (error) {
        
      }
  }

  const getLeadSource = () => {
    let userData = JSON.parse(localStorage.getItem('authUserData'))
    let businessID = userData?.businessDetail?.businessId
      try {
        getLeadSourceService(businessID).then(res => {
                if(res.statusCode === 200){
                if(res.data.status === 1){
                dispatch(addLeadSource(res.data.responseData))
                }else{
                  if(res.data.status === 0){
                    try {
                      const combinedLeadSource = [
                        ...projectOverviewLeadSource.map(_leadSource => ({
                          name: _leadSource.label,
                          color: _leadSource.color
                        }))
                      ];

                      const mergedLeadSource = combinedLeadSource.map((source, index) => ({
                        name: source?.name,
                        isSelected: false, // Assuming all leadSource are initially not selected
                        position: index + 1,
                        color: ''
                    }));

                      const leadSourceSchema = {
                        businessId: businessID,
                        leadSource: mergedLeadSource
                      };
                      addLeadSourceService(leadSourceSchema).then(res => {
                          if(res.data.status === 1){
                            dispatch(addLeadSource(res.data.responseData))
                          }
                      })
                    } catch (error) {
                      
                    }
                  }
                }
              }
          })
      } catch (error) {
        
      }
  }

  const getFavouriteProjects = () => {
          let userData = JSON.parse(localStorage.getItem('authUserData'))
          let businessID = userData?.businessDetail?.businessId
          try {

            getFavProjectService(businessID).then(res => {
              if(res.statusCode === 200){
              if(res.data.status === 1){
              const pipeLineData = getProjectStagesWithTotal(res.data.responseData.projectData)
              setFavProjects(res.data.responseData.projectData)
              }}
            })
                    
          } catch (error) {
            
          }
  }

  function getProjectStagesWithTotal(projectData) {
              const projectStageMap = {};

              // Iterate through the projects and count occurrences of each projectStage
              projectData.forEach(project => {
                  const stage = project.projectStage.name;
                  if (projectStageMap[stage]) {
                      projectStageMap[stage]++;
                  } else {
                      projectStageMap[stage] = 1;
                  }
              });

              // Calculate the total occurrences
              const totalOccurrences = Object.values(projectStageMap).reduce((sum, count) => sum + count, 0);

              // Convert the map to an array of objects
              const projectStageArray = Object.keys(projectStageMap).map(stage => ({
                  name: stage,
                  occurrence: projectStageMap[stage]
              }));

              // Add the "All" entry at the beginning
              projectStageArray.unshift({ name: 'All Projects', occurrence: totalOccurrences });
            return projectStageArray;
  } 

  const getRecentActivities = () => {
        try {
              let userData = JSON.parse(localStorage.getItem('authUserData'))
              let businessID = userData?.businessDetail?.businessId || null
              let query = `?businessId=${businessID}`

              getRecentActivitiesService(query).then(res => {
              if(res.data.status === 1){
                setActivity(res.data.responseData.activity || [])
              }})
        } catch (error) {

        }
  }

  const getBusinessProjects = () => {
        let userData = JSON.parse(localStorage.getItem('authUserData'))
        let businessID = userData?.businessDetail?.businessId || null
        if(businessID) {
              try {
              getBusinessProjectsService(businessID).then(res => {
              if(res.statusCode === 200){
              if(res.data.status === 1){
              dispatch(addProjects(res.data.responseData.projectData));
              setProjectList(res.data.responseData.projectData || [])
              const pipeLineData = getProjectStagesWithTotal(res.data.responseData.projectData)
              localStorage.setItem('pipeLineData', JSON.stringify(pipeLineData))
              setPipeLine(pipeLineData)
              }}})
              } catch (error) { }
        }
  }

  const handleLogout = () => {
            localStorage.clear();
            dispatch(logout());
            persistor.purge() // Clear persisted state
            navigate("/signin");
  };

  const formatDate = (timestamp) => {
            const date = new Date(timestamp);
            const now = new Date();

            const isToday = date.toDateString() === now.toDateString();
            const isYesterday =
              date.toDateString() ===
              new Date(now.setDate(now.getDate() - 1)).toDateString();

            const time = date.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

            if (isToday) {
              return `Today, ${time}`;
            } else if (isYesterday) {
              return `Yesterday, ${time}`;
            } else {
              return `${date.getDate().toString().padStart(2, "0")}-${(
                date.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}-${date.getFullYear()}, ${time}`;
            }
  };
  const getInitials = (text) => {
    return text.split(' ').map(word => word.charAt(0)).join(', ');
  };

  // Name Intital
  useEffect(() => {
    const userData = localStorage.getItem('authUserData');  
    if (userData) {
      const parsedData = JSON.parse(userData);
      const { firstName, lastName } = parsedData;
      if (firstName && lastName) {
        const firstInitial = firstName.charAt(0).toUpperCase();
        const lastInitial = lastName.charAt(0).toUpperCase();
        setNameInitials(`${firstInitial}${lastInitial}`);
      }
    }
  }, []);

  // User Bussiness Name
  useEffect(() => {
    const authUserBusiness = localStorage.getItem('authUserData');
    if (authUserBusiness) {
      const parsedData = JSON.parse(authUserBusiness);
      if (parsedData && parsedData.businessDetail) {
        setBusinessName(parsedData.businessDetail.businessName);
        setBusinessLogo(parsedData.businessDetail.logo);
      }
    }
  }, []);



  useEffect(() => {
    const userData  = JSON.parse(localStorage.getItem('authUserData'));
    const businessId = userData?.businessDetail?.businessId || ''
    const token = localStorage.getItem("token");
    if(businessId !== ''){
      getInvoiceOverview('', businessId, token).then(res => {
        if(res.data.status === 1){
          setInvoiceOverview(res.data.responseData.invoiceOverview)
        }
      })
    }
  },[])

  const [tasks, setTasks] = useState([]);
  const [notes, setNotes] = useState([]);
  let authUserData = JSON.parse(localStorage.getItem("authUserData"));
  const token = localStorage.getItem("token");

  const fetchTaskData = async () => {
    const businessId = authUserData?.businessDetail?.businessId;

    let query = `?businessId=${businessId}`;

    const getList = await getTasksList(query, token);
    setTasks(getList?.data?.responseData?.data);
  };

  useEffect(() => {
    fetchTaskData();
  }, [id]);


  const fetchData = async () => {
    const businessId = authUserData?.businessDetail?.businessId;

    let query = `?businessId=${businessId}`;

    const notesData = await getNotesList(query, token);
    if (notesData?.data?.status === 1) {
      setNotes(notesData?.data?.responseData?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const opentaskDetail = ()=>{
    navigate("/tasks")
  }

  return (
    <div>


      <div className="dashboard_upper projectdetails_dash_upper">

      <div className="projectdetails_dash_left">
        {businessLogo ? <img src={businessLogo} className="fav_star" alt="Star Toggle"/> : ''}
          
          <h3>{businessName}</h3>
      </div>
      <div className="projectdetails_dash_left">

        <GlobalSearch />
          <div className="logout_main_div">
            <div className="name_intials_dash_upper">{nameInitials}</div>
            <div className="logout_div">
              <p onClick={handleLogout}>Logout</p>
            </div>
          </div>

      </div>

      </div>



      {/* <div className="dashboard_upper dashboard_upper2">
        <div className="dashboard_upper_bussiness_logo">
          {businessLogo ? 
          <img src={businessLogo} alt="business logo"/> :
          <div className="logout_main_div">
            <div className="name_intials_dash_upper"></div>
          </div>}
          <p className="dashboard_upper_bussiness_name">{businessName}</p>
        </div>
        <div className="dashboard_upper_right">
          
        </div>
 
        
      </div> */}
      <div className="dashboard_lower">
        {showPipeline ? <PipeLine projectList={projectList} _pipelineData={pipeLine} refreshPipline={getBusinessProjects} phase={phases} id={id} businessId={businessId}/> : <td
                            colSpan={6}
                            style={{
                              fontSize: "16px",
                              fontWeight: 700,
                              textAlign: "center",
                              marginLeft: "550px",
                              marginBottom: '90px',
                              marginTop: '50px'
                            }}
                            className="dashboard_lower arrow_scroll_ul"
                          >
                            <Loader type="2" />
                          </td> }
        <div className="dashboard_detail">
          <div className="dashboard_detail_left">
          {invoiceShow ? 
            <div className="dashboard_detail_left_main">
             <div className="dash_task">
                <div className="dash_task_upper">
                    <div className="heading_symbol">
                      <h3 className="dashboard_heading">Tasks</h3>
                    </div>
                      <span className="dash_task_view" onClick={opentaskDetail} style={{cursor:'pointer'}}>
                      View All
                      </span>
                </div>

                {tasks?.length === 0 ? (
                  <div className="no_task_div">
                    <p>No Task Yet</p>
                  </div>
                ) : (
                  <ul className="task-list">
                    {tasks.slice(0, 5).map((task) => (
                      <li key={task.id} className="task-item">
                        {/* <input type="checkbox" /> */}
                        <div className="task-content" style={{marginBottom: '7px'}}>
                          <h3 className="task-title" 
                           style={{
                                border: 'none',
                                background: 'none',
                                textDecoration: task.taskStatus === 11 ? 'line-through' : 'none',
                                color: task.taskStatus === 11 ? 'gray' : 'black'
                            }}
                          >{task.taskTitle}</h3>
                          <div className="task-details">
                            {/* <p className="project-name">{task.projectName}</p> */}
                            <p className="task-date">
                             {task.projectName} • {new Date(task.dueDate).toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              })}
                            </p>
                          </div>
                          <p className="creator-name">
                            Created by {task?.assingTo?.firstName && task?.assingTo?.lastName ? `${task?.assingTo?.firstName} ${task?.assingTo?.lastName}` : task?.assingTo?.name}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}

              </div>
              <div className="dash_invoice">
                <div className="heading_symbol">
                  <h3 className="dashboard_heading">Invoices</h3>
                </div>
                <ul className="dash_invoice_ul">
                  <li className="dash_invoice_li">
                    <h4 className="font_18">Total</h4>
                    <h5 className="font_16">₹ {invoiceOverviewShow.total ? invoiceOverview.totalAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'XXXXXXXXX'} </h5>
                    <p
                      className="font_14"
                      style={{cursor: 'pointer'}}
                      onClick={() => setInvoiceOverviewShow({...invoiceOverviewShow, total: !invoiceOverviewShow.total})}>
                        {invoiceOverviewShow.total ? 'Hide' : 'Show'}
                      </p>
                  </li>


                  <li className="dash_invoice_li">
                    <h4 className="font_18">Received</h4>
                    <h5 className="font_16">₹ {invoiceOverviewShow.received ? invoiceOverview.receivedAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'XXXXXXXXX'} </h5>
                    <p className="font_14">In Last 30 Days</p>
                    <p
                    style={{cursor: 'pointer'}}
                      className="font_14"
                      onClick={() => setInvoiceOverviewShow({...invoiceOverviewShow, received: !invoiceOverviewShow.received})}>
                        {invoiceOverviewShow.received ? 'Hide' : 'Show'}
                      </p>
                  </li>


                  <li className="dash_invoice_li">
                    <h4 className="font_18">Outstanding</h4>
                    <h5 className="font_16">₹ {invoiceOverviewShow.outstanding ? invoiceOverview.outstandingAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'XXXXXXXXX'} </h5>
                    <p
                    style={{cursor: 'pointer'}}
                      className="font_14"
                      onClick={() => setInvoiceOverviewShow({...invoiceOverviewShow, outstanding: !invoiceOverviewShow.outstanding})}>
                        {invoiceOverviewShow.outstanding ? 'Hide' : 'Show'}
                      </p>
                  </li>


                  <li className="dash_invoice_li">
                    <h4 className="font_18">Overdue</h4>
                    <h5 className="font_16">₹ {invoiceOverviewShow.overdue ? invoiceOverview?.overdue?.amount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'XXXXXXXXX'} </h5>
                    <p className="font_14">Over 30 Days</p>
                    <p
                    style={{cursor: 'pointer'}}
                      className="font_14"
                      onClick={() => setInvoiceOverviewShow({...invoiceOverviewShow, overdue: !invoiceOverviewShow.overdue})}>
                        {invoiceOverviewShow.overdue ? 'Hide' : 'Show'}
                      </p>
                  </li>
                </ul>
              </div>
            </div> 
            :
              <div className="dash_task_main">
                <div className="dash_task_upper">
                  <div className="heading_symbol">
                    <h3 className="dashboard_heading">Tasks</h3>
                  </div>
                  <span className="dash_task_view" onClick={opentaskDetail} style={{cursor:'pointer'}}>
                   View All
                  </span>
                </div>
                
                  {tasks?.length === 0 ? (
                    <div className="no_task_div">
                      <p>No Task Yet</p>
                    </div>
                  ) : (
                    <ul className="task-list">
                    {tasks.slice(0, 5).map((task) => (
                      <li key={task.id} className="task-item">
                        <div className="task-content">
                          <h3 className="task-title" 
                            style={{
                              border: 'none',
                              background: 'none',
                              textDecoration: task.taskStatus === 11 ? 'line-through' : 'none',
                              color: task.taskStatus === 11 ? 'gray' : 'black'
                            }}
                          >
                            {task.taskTitle}
                          </h3>
                          <div className="task-details">
                            <p className="project-creator" style={{color:'#14213D'}}>
                              {task.projectName} • {new Date(task.created_at).toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              })} 
                            </p>
                            <p className="due-date" style={{color:'#71778E'}}>
                              Due on {new Date(task.dueDate).toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              })} •  by {task?.assingTo?.firstName && task?.assingTo?.lastName ? `${task?.assingTo?.firstName} ${task?.assingTo?.lastName}` : task?.assingTo?.name}
                            </p>

                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  
                  )}
              </div> 
            }

            <div className="dash_recent">
              <div className="heading_symbol">
                <h3 className="dashboard_heading">Recent Activity</h3>
                {/* <img src={plusicon} className="plusicon" /> */}
              </div>
              {activity?.length > 0 ? (
                activity.map((item, index) => (
                  <ul className="dash_recent_ul" key={index}>
                    <li className="dash_recent_li" key={index}>
                      <figure className="dash_recent_li_figure">
                        <div className="dash_recent_li_figure_div">{item.userName.slice(0,1)}</div>
                      </figure>
                      <figcaption className="dash_recent_li_figcap">
                        <p className="dash_recent_li_para font_16">
                          {item.description}
                        </p>
                      </figcaption>
                    </li>
                  </ul>
                ))
              ) : (
                <div>No data Found</div>
              )}
            </div>
          </div>

          <div className="dashboard_detail_right">
            
            <QuickActions refreshPipline={getBusinessProjects}/>
            <div className="dash_fav">
              <div className="dash_task_upper">
                <div className="heading_symbol">
                  <h3 className="dashboard_heading">Favourite Projects</h3>
                </div>
                {/* <p className="dash_task_view">View All</p> */}
              </div>
              <ul className="dash_fav_ul">
              {favProjects.length > 0 ? 
                favProjects.map((project, index) => {
                  const formattedDate = formatDate(project.created_at);
                  const displayDate = formattedDate.startsWith("Today") ? formattedDate : `on ${formattedDate}`;
                  
                  return (
                    <li key={index} className="dash_fav_li">
                      <img src={staricon} alt="Star Icon" />
                      <div className="dash_fav_li_div">
                        <h4 className="font_16">{project.projectName}</h4>
                        <h5 className="font_14">Created {displayDate}</h5>
                      </div>
                    </li>
                  );
                })
                : 
                <div className="no_task_div"><p>No Favourites Yet</p></div>
              }
            </ul>
            </div>
            <div className="dash_task dash_notes">
              <div className="dash_task_upper">
                <div className="heading_symbol">
                  <h3 className="dashboard_heading">Notes</h3>
                </div>
                {/* <p className="dash_task_view" >View All</p> */}
              </div>
              <ul className="task-list dash_task_ul">
                {notes.length === 0 ? (
                  <div className="no_task_div"><p>No Notes Yet</p></div>
                ) : (
                  notes.slice(0, 5).map(note => (
                    <li key={note.id} className="task-item">
                       {/* <input type="checkbox" /> */}
                      <div className="task-content">
                        <h4 className="task-title">{note.noteTitle}</h4>
                        <div className="task-details">
                          {/* <p className="project-name">{note.projectName}</p> */}
                          <p className="task-date">{NotesformatDate2(note.created_at)}</p>
                        </div>
                        {/* <p className="creator-name">Created by Umesh Kumar</p> */}
                        {/* <p>{note.notesDescription}</p> */}
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
